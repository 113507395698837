<template>
  <div class="bitts-simple-object-filter">
    <BittsInput
      v-if="searchable"
      v-model="searchQuery"
      :placeholder
      name="object_filter_search"
      type="search"
      class="mb-8"
      @update:model-value="() => emit('search-applied', searchQuery)"
    />
    <div v-if="!loading">
      <div class="max-h-[268px] min-w-[244px] overflow-y-auto">
        <div v-for="item in options" :key="item.value">
          <BittsCheckbox
            :checked="filterValue.includes(item.value as never)"
            :label="item.label"
            @input="
              (checked: boolean) => onSelectionChecked(checked, item.value)
            "
          />
        </div>
      </div>
      <div v-if="!options.length" class="py-8 text-center">
        <BittsEmptyState
          :font-awesome-icon="['fak', 'search']"
          :font-awesome-icon-style="{ height: '26px', width: '26px' }"
          font-awesome-icon-class="bitts-simple-object-filter__empty-state-icon"
          title="No results"
          description="Try a different search term to find fields"
          class="w-[244px] border-none"
        />
      </div>
    </div>
    <div v-else class="py-8 flex justify-center w-[244px]">
      <FontAwesomeIcon
        :icon="['fak', 'loading']"
        spin
        class="text-neutral-accent w-28 h-28"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';

import BittsCheckbox from '../../BittsCheckbox/BittsCheckbox.vue';
import BittsEmptyState from '../../BittsEmptyState/BittsEmptyState.vue';
import BittsInput from '../../BittsInput/BittsInput.vue';

const {
  value = [],
  options = [],
  placeholder = 'Search for a value',
  searchable = true,
  search = '',
  loading = false,
} = defineProps<{
  value?: (string | number)[];
  placeholder?: string;
  options?: { label: string; value: string | number }[];
  searchable?: boolean;
  loading?: boolean;
  search?: string;
}>();

const emit = defineEmits<{
  (e: 'search-applied', query: string): void;
  (e: 'filter-changed', filterValue: (string | number)[]): void;
  (e: 'filter-selected', value: string | number, checked: boolean): void;
}>();

const filterValue = ref([...value]);
const searchQuery = ref('');

function onSelectionChecked(checked: boolean, value: string | number) {
  if (checked) {
    filterValue.value.push(value);
  } else {
    filterValue.value = filterValue.value.filter((val) => val !== value);
  }
  emit('filter-changed', filterValue.value);
  emit('filter-selected', value, checked);
}

watchEffect(() => {
  filterValue.value = [...value];
});
watchEffect(() => {
  searchQuery.value = search;
});
</script>
<style lang="pcss" scoped>
.bitts-simple-object-filter {
  @apply px-16 py-8;
}

:deep(.c-bitts-empty-state-large) {
  @apply p-8 pb-0;
}

:deep(.bitts-simple-object-filter__empty-state-icon) {
  @apply text-neutral-text-weak p-20 rounded-[36px];
  background: radial-gradient(
      136.96% 69.92% at 50.09% -12.42%,
      theme(colors.neutral.accent / 0.2) 0%,
      theme(colors.neutral.accent / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
}

:deep(.c-bitts-input .c-bitts-input__input .ant-input-clear-icon) {
  @apply hidden;
}
</style>
